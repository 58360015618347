import React, { useState, useEffect, useContext } from "react";
import "./form.css";

import { Context } from "../store";
const Financial = ({ onPrev, updateFormData, loading, formDataMain, showModel, setShowModel }) => {
  const options = ["Select One", "Yes", "No"];
  const { onSubmit } = useContext(Context);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Select One");
  const [honorsClassesContent, setHonorsClassesContent] = useState("");
  const [extracurricularContent, setExtracurricularContent] = useState("");
  const [awardsContent, setAwardsContent] = useState("");
  const [communityContent, setCommunityContent] = useState("");
  const [essayContent, setEssayContent] = useState("");
  const [isEssayValid, setIsEssayValid] = useState(false);
  const [error] = useState({});
  //console.log("ERR", error);
  //console.log(isEssayValid);
  function formValidation() {
    let newErr = { ...error };
    if (essayContent === "") {
      newErr.essayContent = "This field is required!";
    } else {
      delete newErr.essayContent;
    }
  }

  useEffect(() => {
    setIsChecked(formDataMain.isChecked);
    setHonorsClassesContent(formDataMain.honorsClassesContent);
    setSelectedValue(formDataMain.selectedValue);
    setExtracurricularContent(formDataMain.extracurricularContent);
    setAwardsContent(formDataMain.awardsContent);
    setCommunityContent(formDataMain.communityContent);
    setEssayContent(formDataMain.essayContent);
  }, []);

  useEffect(() => {
    setIsEssayValid(essayContent?.length > 0 && essayContent.split(/\s+/)?.length >= 100);
  }, [essayContent]);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    updateFormData({
      isChecked: newCheckedState,
      selectedValue,
      honorsClassesContent,
      extracurricularContent,
      awardsContent,
      communityContent,
      essayContent,
    });
  };

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    updateFormData({
      isChecked,
      selectedValue: newValue,
      honorsClassesContent,
      extracurricularContent,
      awardsContent,
      communityContent,
      essayContent,
    });
  };

  const handleTextAreaChange = (setterFunction, key) => (event) => {
    const newValue = event.target.value;
    formValidation();
    setterFunction(newValue);
    // Since setState is asynchronous, consider updating form data after state has been set
    setTimeout(() => {
      updateFormData({
        isChecked,
        selectedValue,
        // Dynamically update the specific field that changed
        [key]: newValue,
        // Include other fields that did not change
        ...(key !== "honorsClassesContent" && { honorsClassesContent }),
        ...(key !== "extracurricularContent" && { extracurricularContent }),
        ...(key !== "awardsContent" && { awardsContent }),
        ...(key !== "communityContent" && { communityContent }),
        ...(key !== "essayContent" && { essayContent }),
      });
    }, 0);
  };

  //   const isSubmitButtonDisabled = !isChecked || !isEssayValid || essayContent.split(/\s+/)?.length < 100;

  if (loading) {
    return (
      <div className="loader_overlay">
        <div className="loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="fin">
      <p className="divider">
        <hr className="hr" />
      </p>
      <div className="boxfin">
        <h3>Have You Received Any Other Scholarships?</h3>
        <select
          style={{ width: "23rem", height: "2.7rem", fontSize: "medium" }}
          id="financialScholarshipDropdown"
          className="financialScholarshipDropdown"
          value={selectedValue}
          onChange={handleDropdownChange}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <h3>List Any Honors or AP Classes & Grades Received</h3>
        <textarea
          id="financialHonorsClassesContent"
          className="financialHonorsClassesContent"
          value={honorsClassesContent}
          onChange={handleTextAreaChange(setHonorsClassesContent, "financialHonorsClassesContent")}
        />

        <h3>Extracurricular Activities</h3>
        <p>Organizations & Clubs (Show years of involvement and any offices held)</p>
        <textarea
          id="financialExtracurricularContent"
          className="financialExtracurricularContent"
          value={extracurricularContent}
          onChange={handleTextAreaChange(setExtracurricularContent, "financialExtracurricularContent")}
        />

        <h3>Honors & Awards</h3>
        <textarea id="financialAwardsContent" className="financialAwardsContent" value={awardsContent} onChange={handleTextAreaChange(setAwardsContent, "financialAwardsContent")} />

        <h3>Community or Other Activities</h3>
        <textarea id="financialCommunityContent" className="financialCommunityContent" value={communityContent} onChange={handleTextAreaChange(setCommunityContent, "financialCommunityContent")} />

        <h3>
          Essay<span style={{ color: "red", fontWeight: "bold" }}>*</span>
        </h3>
        <p>
          In the space below, please write a 100 to 150 word essay stating why you want to attend college, what you plan to study, why you selected your
          <br /> major, your future career goals, and how receiving this scholarship will positively impact your life.
        </p>
        <textarea id="financialEssayContent" className="financialEssayContent" value={essayContent} onChange={handleTextAreaChange(setEssayContent, "financialEssayContent")} />
        <span style={{ color: "red", fontWeight: "bold", display: "block" }}>{isEssayValid ? "" : "Please write a 100 to 150 word essay "}</span>

        <h3>
          Student Requirements and Release Authorization
          <span style={{ color: "red", fontWeight: "bold" }}>*</span>
        </h3>
        <p>
          {" "}
          In order to be considered for an Ellis Foundation scholarship, please click the following link, a new window will be opened, and complete the{" "}
          <a
            className="link-el"
            href="https://powerforms.docusign.net/3bb79c41-02bf-4b0e-b5ed-a269269eb9d9?env=na4&acct=396469bb-de1a-49c4-a140-0e9b262f97fd&accountId=396469bb-de1a-49c4-a140-0e9b262f97fd"
          >
            23-24 Ellis Foundation Student Release and Requirements
          </a>
        </p>
        <div className="lastcheck">
          <div className="required-check2">
            <label className="agree">
              <input type="checkbox" name="agreement" checked={isChecked} onChange={handleCheckboxChange} />
              <b>
                Once completed, return to this page and check this box to acknowledge you have signed and submitted the release and requirements for The Ellis Foundation scholarship through DocuSign.
                Please note, this can only be completed one time and is the final step in the application. If, for any reason, you are unable to complete the application in this sitting - you will NOT
                be required to resubmit the paperwork when you start the application again.
              </b>
            </label>
          </div>
        </div>
      </div>
      <p className="divider">
        <hr className="hr" />
      </p>
      <button className="button-form" onClick={onPrev}>
        PREVIOUS
      </button>
      <button style={{ marginLeft: "1rem" }} className="button-form" onClick={onSubmit} disabled={!(isEssayValid && isChecked)}>
        {loading ? "Loading..." : "SUBMIT"}
      </button>
    </div>
  );
};

export default Financial;
