import React, { useEffect, useState } from "react";
import "./pers.css";
import "./College.js";
import College from "./College.js";
import { useMediaQuery } from "react-responsive";
import { axios_ } from "../axios.js";

const Personal = ({ onNext, onPrev, updateFormData, isValid, formDataMain, showModel, setShowModel }) => {
    const [formData, setFormData] = useState({
        actOrSatScore: "",
        highSchoolGPA: "",
        highSchoolName: "",
        selectSchool: "",
        otherHighSchoolName: "",
        highSchoolCounselor: "",
        highSchoolAddress: "",
        city: "",
        state: "",
        zip: "",
        County: "United States",
        highSchoolPhone: "",
        highSchoolEmail: "",
    });

    const [collegeForms, setCollegeForms] = useState([
        {
            // Initialize with default college form fields
            selectCollege: "",
            careerobj: "",
            otherCollegeCareer: "",
            degree: "",
            address: "",
            addressLine2: "",
            city: "",
            zip: "",
            collegePhone: "",
            state: "",
            County: "United States",
            collegeEmail: "",
        },
    ]);

    const [, setIsHighSchoolSelected] = useState(false);
    const [highSchoolList, setHighSchoolList] = useState([]);
    const [country, setCountry] = useState([]);
    const [carrerOptions, setCareerOptions] = useState([]);
    const [error, setError] = useState({});
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [gloaleError, setGlobalError] = useState(false);
    const [isMail, setIsEmail] = useState(true);
    const [collegeEmail, setCollegeEmail] = useState(true);

    const requiredFields = ["selectSchool"];

    function handleGlobalError() {
        const hasError = requiredFields.some((field) => formData[field] === "");
        setGlobalError(hasError);
        //console.log("hasError", hasError);
        return hasError;
    }
    useEffect(() => {
        setFormData(formDataMain.personalData || {});
        setCollegeForms(formDataMain.collegeForms || []);
    }, []);

    function validateMail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    useEffect(() => {
        setIsEmail(validateMail(formData.highSchoolEmail));
        //console.log("collegeEmail1", formData.highSchoolEmail);
    }, [formData.highSchoolEmail]);

    useEffect(() => {
        setCollegeEmail(validateMail(collegeForms.collegeEmail));
        //console.log("highschoolemial2", collegeForms.collegeEmail);
        //console.log(collegeEmail);
    }, [collegeForms.collegeEmail]);

    function formValidation() {
        let newError = { ...error };

        if (formData.highSchoolName === "") {
            newError.highSchoolName = "This field is required";
        } else {
            delete newError.highSchoolName;
        }
        setError(newError);
    }

    const handleChange = (field, value) => {
        const newFormData = { ...formData, [field]: value };

        formValidation();
        setFormData(newFormData);
        setIsHighSchoolSelected((newFormData.selectSchool !== "" && newFormData.selectSchool !== "other") || (newFormData.selectSchool === "other" && newFormData.otherHighSchoolName !== ""));
        updateFormData({ personalData: newFormData, collegeForms: collegeForms });
    };

    async function getCountry() {
        try {
            let response = await axios_.get("dropDownList/Country");
            let data = await response.json();
            setCountry(data.data[0]);
        } catch (e) {
            console.log(e);
        }
    }
    async function getCarrerOptions() {
        try {
            let response = await axios_.get("dropDownList/Career");
            let data = await response.json();
            setCareerOptions(data.data[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function getHighSchool() {
        try {
            let response = await axios_.get("dropDownList/Highschool");
            let data = await response.json();
            setHighSchoolList(data.data[0]);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getHighSchool();
        getCountry();
        getCarrerOptions();
    }, []);

    const addCollegeForm = () => {
        const newForm = {
            selectCollege: "",
            careerobj: "",
            otherCollegeCareer: "",
            degree: "",
            address: "",
            addressLine2: "",
            city: "",
            zip: "",
            collegePhone: "",
            state: "",
            County: "United States",
            collegeEmail: "",
        };
        const newCollegeForms = [...collegeForms, newForm];
        setCollegeForms(newCollegeForms);
        updateFormData({ personalData: formData, collegeForms: newCollegeForms });
    };
    const deleteCollegeForm = (index) => {
        const newForms = collegeForms.filter((_, idx) => idx !== index);
        setCollegeForms(newForms);
        updateFormData({ personalData: formData, collegeForms: newForms });
    };

    const handleCollegeChange = (index, field, value) => {
        const updatedForms = collegeForms.map((form, idx) => {
            if (idx === index) {
                return { ...form, [field]: value };
            }
            return form;
        });
        setCollegeForms(updatedForms);
        //console.log("collegeForm", collegeForms);
        updateFormData({ personalData: formData, collegeForms: updatedForms });
    };

    // const isNextEnabled = () => {
    //     return (
    //         isHighSchoolSelected &&
    //         collegeForms.every(
    //             (collegeData) =>
    //                 collegeData.careerobj && collegeData.careerobj !== "" && (collegeData.careerobj !== "other" || (collegeData.careerobj === "other" && collegeData.otherCollegeCareer !== ""))
    //         )
    //     );
    // };

    return (
        <div style={{ color: "#1a3c51" }}>
            <div>
                <p className="divider">
                    <hr className="hr " />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        {gloaleError && <h1 style={{ color: "red", fontSize: "20px" }}>Please fill all the required fields!</h1>}
                    </div>
                </p>
                <h1
                    style={{
                        marginLeft: isMobile ? "2rem" : "13.5rem",
                        fontSize: "1.4rem",
                    }}>
                    High School
                </h1>
                <p className="divider">
                    <hr className="hr " />
                </p>
            </div>
            <div
                className="form-container2"
                style={{
                    marginLeft: isMobile ? "1rem" : "28.5rem",
                    width: isMobile ? "100%" : "900px",
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        marginLeft: isMobile ? "0" : "-13.5rem",
                        marginTop: isMobile ? "0" : "0.75rem",
                        width: isMobile ? "auto" : "60rem",
                    }}>
                    <div className="col1" style={{ marginRight: isMobile ? "1rem" : "5rem" }}>
                        <div>
                            <h2>ACT or SAT Score</h2>
                            <input type="number" value={formData.actOrSatScore} onChange={(e) => handleChange("actOrSatScore", e.target.value)} tabIndex={1} />
                        </div>

                        <div>
                            <h2>
                                High School Name
                                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </h2>

                            <select
                                value={formData.selectSchool}
                                onChange={(e) => handleChange("selectSchool", e.target.value)}
                                style={{
                                    width: isMobile ? "100%" : "31.7rem",
                                    height: "2.5rem",
                                    fontSize: "1rem",
                                    verticalAlign: "bottom",
                                    paddingRight: "10px",
                                }}>
                                <option value={""}>Select One</option>
                                {highSchoolList?.map((school, ind) => {
                                    return (
                                        <option key={ind} value={school.ChildName}>
                                            {school.ChildName}
                                        </option>
                                    );
                                })}
                                <span style={{ color: "red", fontWeight: "bold" }}>{error.highSchoolName}</span>
                            </select>

                            <p>If your high school is not on the list below, please select 'Other' and enter your high school's name below.</p>
                            {formData.selectSchool === "other" && (
                                <div style={{ marginTop: "1rem" }}>
                                    <h2>
                                        Specify High School Name
                                        <span style={{ color: "red", fontWeight: "bold" }}> *</span>
                                    </h2>
                                    <input
                                        type="text"
                                        value={formData.otherHighSchoolName}
                                        onChange={(e) => handleChange("otherHighSchoolName", e.target.value)}
                                        style={{
                                            width: "30rem", // Adjusted to match select
                                            height: "2.3rem", // Adjusted to match select
                                            fontSize: "1.1rem", // Consistency in font size
                                        }}
                                        tabIndex={3}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col2">
                        <div>
                            <h2>High School GPA</h2>
                            <input type="number" value={formData.highSchoolGPA} onChange={(e) => handleChange("highSchoolGPA", e.target.value)} tabIndex={2} />
                        </div>

                        <div>
                            <h2>High School Counselor</h2>
                            <input type="text" value={formData.highSchoolCounselor} onChange={(e) => handleChange("highSchoolCounselor", e.target.value)} tabIndex={4} />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        marginLeft: isMobile ? "0" : "-13.5rem",
                        marginTop: isMobile ? "0" : "0.75rem",
                        width: isMobile ? "auto" : "60rem",
                    }}>
                    <div className="col1" style={{ marginRight: isMobile ? "1rem" : "5rem" }}>
                        <h2>High School Address</h2>

                        <div>
                            <h2>City</h2>
                            <input type="text" value={formData.city} onChange={(e) => handleChange("city", e.target.value)} tabIndex={5} />
                        </div>

                        <div>
                            <h2>ZIP / Postal Code</h2>
                            <input type="text" value={formData.zip} onChange={(e) => handleChange("zip", e.target.value)} tabIndex={7} />
                        </div>

                        <div>
                            <h2>High School Phone</h2>
                            <input type="tel" value={formData.highSchoolPhone} onChange={(e) => handleChange("highSchoolPhone", e.target.value)} tabIndex={9} />
                        </div>
                    </div>

                    <div className="col2" style={{ marginTop: "2.55rem" }}>
                        <div>
                            <h2>State / Province / Region</h2>
                            <input type="text" value={formData.state} onChange={(e) => handleChange("state", e.target.value)} tabIndex={6} />
                        </div>

                        <div>
                            <h2>County</h2>
                            <select style={{ width: isMobile ? "100%" : "31.7rem" }} value={formData.County} onChange={(e) => handleChange("County", e.target.value)} tabIndex={8}>
                                {country?.map((country, ind) => {
                                    return (
                                        <option key={ind} value={country.ChildName}>
                                            {country.ChildName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <h2>High School Email</h2>
                            <input type="email" value={formData.highSchoolEmail} onChange={(e) => handleChange("highSchoolEmail", e.target.value)} tabIndex={10} />
                            <span style={{ color: "red", fontWeight: "bold" }}>{isMail ? "" : <p>Please Enter Valid Mail</p>}</span>
                        </div>
                    </div>
                </div>
            </div>

            {collegeForms.map((collegeData, index) => (
                <div key={`collegeForm-${index}`}>
                    {" "}
                    {/* Prefer unique id, fallback to index */}
                    <College
                        country={country}
                        carrerOptions={carrerOptions}
                        highSchoolList={highSchoolList}
                        collegeData={collegeData}
                        onChange={(field, value) => handleCollegeChange(index, field, value)}
                        onDelete={() => deleteCollegeForm(index)}
                        collegeEmail={collegeEmail}
                    />
                    {index > 0 && (
                        <div style={{ marginTop: "0.5rem", fontSize: "small" }}>
                            <button className="button-form" onClick={() => deleteCollegeForm(index)}>
                                DELETE
                            </button>
                        </div>
                    )}
                </div>
            ))}

            <div style={{ marginTop: "0.5rem", fontSize: "small" }}>
                <button className="button-form" onClick={addCollegeForm} disabled={collegeForms.length >= 3}>
                    ADD ANOTHER COLLEGE
                </button>
            </div>

            <div style={{ marginTop: "2.5rem", fontSize: "small" }}>
                <p className="divider">
                    <hr className="hr " />
                </p>
                <button className="button-form" onClick={onPrev}>
                    PREVIOUS
                </button>
                <button className="button-form2" onClick={(e) => onNext(e, gloaleError, handleGlobalError)} disabled={false}>
                    NEXT
                </button>
            </div>
        </div>
    );
};

export default Personal;
