import React, { useState, useEffect } from "react";
import "./photo.css";
import { useMediaQuery } from "react-responsive";
import { axios_ } from "../axios";

const SeniorPhoto = ({ onNext, onPrev, updateFormData, formData1, setFormData1, showModel, setShowModel }) => {
    const [country, setCountry] = useState([]);
    const [error, setError] = useState({});
    const [gloaleError, setGlobalError] = useState(false);
    const [isMail, setIsEmail] = useState(true);

    const requiredFields = ["referredBy", "todayDate", "fullName", "address", "addressLine2", "city", "zip", "Country", "email", "dob"];

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [, setIsFormValid] = useState(false);

    function handleGlobalError() {
        const hasError = requiredFields.some((field) => formData1[field] === "");
        setGlobalError(hasError);
        return hasError;
    }
    function validateMail() {
        const eamilRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (eamilRegex.test(formData1.email)) {
            setIsEmail(true);
        } else {
            setIsEmail(false);
        }
        return isMail;
    }

    function formValidation() {
        const newError = { ...error };
        requiredFields.forEach((field) => {
            if (formData1[field] === "") {
                newError[field] = "This field is required";
            } else {
                delete newError[field];
            }
        });
        setError(newError);
    }

    async function getCountry() {
        try {
            let response = await axios_.get("dropDownList/Country");
            if (!response.ok) {
                throw new Error(`HTTP Error ${response.status}`);
            }
            let data = await response.json();
            setCountry(data.data[0]);
        } catch (e) {
            console.log(e);
        }
    }
    async function getAllData() {
        try {
            let response = await axios_.get("all");
            if (!response.ok) {
                throw new Error(`HTTP Error ${response.status}`);
            }
            await response.json();
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        getCountry();
        getAllData();
    }, []);

    const handleChange = (field, value) => {
        const newFormData = { ...formData1, [field]: value };
        formValidation();
        setFormData1(newFormData);
        validateMail();

        validateForm(newFormData);

        updateFormData({ seniorPhotoData: newFormData });
    };

    const validateForm = (formDataToValidate) => {
        const requiredFields = ["referredBy", "todayDate", "fullName", "address", "addressLine2", "city", "zip", "County", "email", "dob"];
        const isValid = requiredFields.every((field) => !!formDataToValidate[field]);
        setIsFormValid(isValid);
    };

    // useEffect(() => {
    //   validateForm(formData);
    // }, [formData]);

    return (
        <div style={{ color: "#1a3c51", fontSize: "small" }}>
            <p className="divider">
                <hr className="hr " />
            </p>
            <div
                className="form-container"
                style={{
                    marginLeft: isMobile ? "1rem" : "28.5rem",
                    width: isMobile ? "100%" : "700px",
                }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "10px",
                    }}>
                    {gloaleError && <h1 style={{ color: "red" }}>Please fill all the required fields!</h1>}
                </div>
                {/* ***************************************************** */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        marginLeft: isMobile ? "0" : "-13.5rem",
                        marginTop: isMobile ? "1rem" : "0.75rem",
                        width: isMobile ? "auto" : "60rem",
                    }}>
                    {/* ****** */}
                    <div className="col1" style={{ marginRight: isMobile ? "0" : "6rem" }}>
                        <div className={error.referredBy ? "brError" : ""}>
                            <h2>
                                Referred By
                                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </h2>
                            <p style={{ fontWeight: "bold" }}>Name & Title, E.g. Counselor, Ellis Foundation Website</p>
                            <input
                                style={{ marginTop: "1.2rem", width: isMobile ? "100%" : "" }}
                                type="text"
                                value={formData1.referredBy}
                                required
                                onChange={(e) => handleChange("referredBy", e.target.value)}
                                tabIndex={1}
                            />
                            <span style={{ color: "red", fontWeight: "bold" }}>{error.referredBy}</span>
                        </div>

                        <div>
                            <h2>
                                Today's Date<span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </h2>
                            <div>
                                <input
                                    className="large-calendar"
                                    style={{ height: "2.4rem" }}
                                    type="date"
                                    value={formData1.todayDate || formatDate(new Date())}
                                    onChange={(e) => handleChange("todayDate", e.target.value)}
                                    tabIndex={3}
                                />
                                <span style={{ color: "red", fontWeight: "bold" }}>{error.todayDate}</span>
                            </div>
                        </div>
                    </div>

                    {/* ************** */}

                    <div className="col2" style={{ marginLeft: isMobile ? "0" : "-1rem" }}>
                        <div>
                            <h2>Are you applying for a specific Scholarship?</h2>
                            <p style={{ width: "34rem", fontWeight: "bold" }}>
                                Are you applying for a specific Scholarship? Such as Kansas Skilled Trades, Advisors Excel, Lockton, Rooted in Rural, or a general Ellis award. If so, which one?
                            </p>
                        </div>
                        <div>
                            <input
                                style={{ marginTop: "0.1rem" }}
                                type="text"
                                value={formData1.specificScholarship}
                                onChange={(e) => handleChange("specificScholarship", e.target.value)}
                                tabIndex={2}
                            />
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                            <h2>
                                Student's Full Legal Name
                                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </h2>
                            <input type="text" value={formData1.fullName} onChange={(e) => handleChange("fullName", e.target.value)} tabIndex={4} />
                            <span style={{ color: "red", fontWeight: "bold", display: "block" }}>{error.fullName}</span>
                        </div>
                    </div>
                </div>

                {/* -*************************************** */}

                <div
                    style={{
                        marginLeft: isMobile ? "0rem" : "-13.5rem",
                        marginTop: "1.17rem",
                    }}>
                    <h2>Name Student Goes By</h2>
                    <input type="text" value={formData1.nameUsed} onChange={(e) => handleChange("nameUsed", e.target.value)} tabIndex={5} />
                </div>

                <div
                    style={{
                        marginLeft: isMobile ? "0rem" : "-13.5rem",
                        marginTop: "1.17rem",
                        width: isMobile ? "100%" : "auto",
                    }}>
                    {" "}
                    <h2>
                        Student's Address
                        <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                    </h2>
                    <h3>Street Address</h3>
                    <input type="text" style={{ width: isMobile ? "100%" : "66.5rem" }} value={formData1.address} onChange={(e) => handleChange("address", e.target.value)} tabIndex={6} />
                    <span style={{ color: "red", fontWeight: "bold" }}>{error.address}</span>
                    <h3> Address Line 2</h3>
                    <input style={{ width: isMobile ? "100%" : "66.5rem" }} type="text" value={formData1.addressLine2} onChange={(e) => handleChange("addressLine2", e.target.value)} tabIndex={7} />
                    <span style={{ color: "red", fontWeight: "bold" }}>{error.addressLine2}</span>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            marginLeft: isMobile ? "0rem" : "0rem",
                            marginTop: isMobile ? "1rem" : "0.75rem",
                            width: isMobile ? "auto" : "60rem",
                        }}>
                        <div className="col1" style={{ marginRight: isMobile ? "4rem" : "6rem" }}>
                            <div>
                                <h2>City</h2>
                                <input type="text" value={formData1.city} onChange={(e) => handleChange("city", e.target.value)} tabIndex={8} />
                                <span style={{ color: "red", fontWeight: "bold" }}>{error.city}</span>
                            </div>

                            <div>
                                <h2>ZIP / Postal Code</h2>
                                <input type="text" value={formData1.zip} onChange={(e) => handleChange("zip", e.target.value)} tabIndex={10} />
                                <span style={{ color: "red", fontWeight: "bold" }}>{error.zip}</span>
                            </div>

                            <div>
                                <h2>Cell Phone</h2>
                                <input type="tel" value={formData1.cellPhone} onChange={(e) => handleChange("cellPhone", e.target.value)} tabIndex={12} />
                            </div>

                            <h2>
                                Email
                                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </h2>
                            <input type="email" value={formData1.email} onChange={(e) => handleChange("email", e.target.value)} tabIndex={14} />
                            <span style={{ color: "red", fontWeight: "bold" }}>{isMail ? "" : <p>Please Enter Valid Mail</p>}</span>

                            <div>
                                <h2>SSN</h2>
                                <input type="text" value={formData1.ssn} onChange={(e) => handleChange("ssn", e.target.value)} tabIndex={16} />
                            </div>
                        </div>

                        <div className="col2" style={{ marginLeft: isMobile ? "0rem" : "-1rem" }}>
                            <div>
                                <h2>State / Province / Region</h2>
                                <input type="text" value={formData1.state} onChange={(e) => handleChange("state", e.target.value)} tabIndex={9} />
                            </div>

                            <div style={{ marginTop: "1rem" }}>
                                <h2>
                                    Country
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </h2>
                                <select style={{ width: "31.7rem" }} value={formData1.County} onChange={(e) => handleChange("Country", e.target.value)} tabIndex={11}>
                                    <option value="">Select One</option>
                                    {country?.map((country, index) => {
                                        return (
                                            <option value={country.ChildName} key={index}>
                                                {country.ChildName}
                                            </option>
                                        );
                                    })}
                                </select>
                                <span style={{ color: "red", fontWeight: "bold" }}>{error.County}</span>
                            </div>

                            <div style={{ marginTop: "0.6rem" }}>
                                <h2>Home Phone</h2>
                                <input type="tel" value={formData1.homePhone} onChange={(e) => handleChange("homePhone", e.target.value)} tabIndex={13} />
                            </div>

                            <div style={{ marginTop: "1rem" }}>
                                <h2>
                                    DOB
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </h2>
                                <input className="large-calendar" style={{ height: "2.3rem" }} type="date" value={formData1.dob} onChange={(e) => handleChange("dob", e.target.value)} tabIndex={15} />
                                <span style={{ color: "red", fontWeight: "bold" }}>{error.dob}</span>
                            </div>

                            <div style={{ marginTop: "1.1rem" }}>
                                <h2>Sex</h2>
                                <select style={{ width: isMobile ? "auto" : "31.7rem" }} value={formData1.sex || ""} onChange={(e) => handleChange("sex", e.target.value)} tabIndex={17}>
                                    <option value="">Select One</option>

                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        marginLeft: isMobile ? "0rem" : "-13.5rem",
                        marginTop: isMobile ? "1rem" : "2.4rem",
                        marginBottom: isMobile ? "0" : "2rem",
                    }}>
                    <h2>Ethnicity</h2>
                    <select style={{ width: isMobile ? "27rem" : "31.7rem" }} value={formData1.ethnicity || ""} onChange={(e) => handleChange("ethnicity", e.target.value)}>
                        <option value="">Select One</option>
                        <option value="White">White</option>
                        <option value="African-American">African-American</option>
                        <option value="Hispanic">Hispanic</option>
                        <option value="Indian">Indian</option>
                        <option value="Asian">Asian</option>
                        <option value="American-Indian">American-Indian</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>

            <p className="divider">
                <hr className="hr " />
            </p>

            <button className="button-form" onClick={onPrev}>
                PREVIOUS
            </button>
            <button className="button-form2" onClick={(e) => onNext(e, gloaleError, handleGlobalError, validateMail, formValidation)}>
                NEXT
            </button>
        </div>
    );
};

export default SeniorPhoto;

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
};
