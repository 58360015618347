import React from "react";
import "./pers.css";
import { useMediaQuery } from "react-responsive";

const College = ({ collegeData, onChange, onDelete, highSchoolList, carrerOptions, country, collegeEmail }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    function validateMail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    return (
        <div>
            <div>
                <h1 style={{ marginTop: "3rem", marginLeft: "15rem", fontSize: "1.4rem" }}>College</h1>
                <p className="divider" style={{ width: "70%", marginLeft: "14.5rem" }}>
                    <hr className="hr " />
                </p>
            </div>
            <div
                className="form-container2"
                style={{
                    marginLeft: isMobile ? "1rem" : "28.5rem",
                    width: isMobile ? "100%" : "900px",
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        marginLeft: isMobile ? "0" : "-13.5rem",
                        marginTop: isMobile ? "0" : "0.75rem",
                        width: isMobile ? "auto" : "60rem",
                    }}>
                    <div className="col1" style={{ marginRight: isMobile ? "1rem" : "5rem" }}>
                        <div>
                            <h2>College Choice</h2>
                            <select value={collegeData.selectCollege} onChange={(e) => onChange("selectCollege", e.target.value)} tabIndex={11}>
                                <option value={""}>Select One</option>
                                {highSchoolList?.map((school, ind) => {
                                    return (
                                        <option key={ind} value={school.ChildName}>
                                            {school.ChildName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                            <h2>
                                Career Objective
                                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </h2>
                            <select
                                value={collegeData.careerobj}
                                onChange={(e) => onChange("careerobj", e.target.value)}
                                style={{ width: "30.5rem", height: "2.5rem", fontSize: "1rem" }}
                                tabIndex={13}>
                                <option value={""}>Select One</option>
                                {carrerOptions?.map((career, ind) => (
                                    <option key={ind} value={career.ChildName}>
                                        {career.ChildName}
                                    </option>
                                ))}
                            </select>
                            <p>If your career is not on the list below, please select 'Other' and enter your college choice in the box below.</p>
                        </div>
                    </div>

                    <div className="col2">
                        <div>
                            <h2>College Degree</h2>
                            <input type="text" value={collegeData.degree} onChange={(e) => onChange("degree", e.target.value)} tabIndex={12} />
                        </div>

                        {collegeData.careerobj === "other" && (
                            <div style={{ marginTop: "1rem" }}>
                                <h2>
                                    Specific Career Objective
                                    <span style={{ color: "red", fontWeight: "bold" }}> *</span>
                                </h2>
                                <input style={{ width: "28.8rem" }} type="text" value={collegeData.otherCollegeCareer} onChange={(e) => onChange("otherCollegeCareer", e.target.value)} />
                            </div>
                        )}
                    </div>
                </div>

                <div
                    style={{
                        marginLeft: isMobile ? "0rem" : "-13.5rem",
                        marginTop: "1.5rem",
                        width: isMobile ? "auto" : "60rem",
                    }}>
                    <h2>College Address</h2>
                    <h3>Street Address</h3>
                    <input
                        type="text"
                        style={{
                            width: isMobile ? "100%" : "66.5rem",
                            marginBottom: "1rem",
                        }}
                        value={collegeData.address}
                        onChange={(e) => onChange("address", e.target.value)}
                        tabIndex={14}
                    />

                    <h3> Address Line 2</h3>
                    <input
                        style={{
                            width: isMobile ? "100%" : "66.5rem",
                            marginBottom: "1rem",
                        }}
                        type="text"
                        value={collegeData.addressLine2}
                        onChange={(e) => onChange("addressLine2", e.target.value)}
                        tabIndex={15}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        marginLeft: isMobile ? "0" : "-13.5rem",
                        marginTop: isMobile ? "0" : "0.75rem",
                        width: isMobile ? "auto" : "60rem",
                    }}>
                    <div className="col1" style={{ marginRight: isMobile ? "1rem" : "6rem" }}>
                        <div>
                            <h2>City</h2>
                            <input type="text" value={collegeData.city} onChange={(e) => onChange("city", e.target.value)} tabIndex={16} />
                        </div>

                        <div>
                            <h2>ZIP / Postal Code</h2>
                            <input type="text" value={collegeData.zip} onChange={(e) => onChange("zip", e.target.value)} tabIndex={18} />
                        </div>

                        <div>
                            <h2>College Phone</h2>
                            <input type="tel" value={collegeData.collegePhone} onChange={(e) => onChange("collegePhone", e.target.value)} tabIndex={20} />
                        </div>
                    </div>

                    <div className="col2" style={{ marginTop: "0rem" }}>
                        <div>
                            <h2>State / Province / Region</h2>
                            <input style={{ width: "28.8rem" }} type="text" value={collegeData.state} onChange={(e) => onChange("state", e.target.value)} tabIndex={17} />
                        </div>

                        <div>
                            <h2>Country</h2>
                            <select style={{ marginTop: "0.1rem" }} value={collegeData.County} onChange={(e) => onChange("County", e.target.value)} tabIndex={19}>
                                <option value={""}>Select One</option>
                                {country?.map((country, ind) => {
                                    return (
                                        <option key={ind} value={country.ChildName}>
                                            {country.ChildName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div>
                            <h2>College Email</h2>
                            <input style={{ width: "28.8rem" }} type="email" value={collegeData.collegeEmail} onChange={(e) => onChange("collegeEmail", e.target.value)} tabIndex={21} />
                            <span style={{ color: "red", fontWeight: "bold" }}>{validateMail(collegeData.collegeEmail) ? "" : <p>Please Enter Valid Mail</p>}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default College;
